import React from "react";

import { Container, Links, ExternalLink, RightSideText } from "./styles";

const Footer: React.FunctionComponent = () => {
  return (
    <Container>
      <Links>
        <ExternalLink
          target="_blank"
          href="https://textise.wordpress.com/for-web-developers/"
        >
          Dev Resources
        </ExternalLink>
        <ExternalLink
          target="_blank"
          href="https://textise.wordpress.com/contact-us/"
        >
          Contact Us
        </ExternalLink>
      </Links>

      <RightSideText>© Textise</RightSideText>
    </Container>
  );
};

export default Footer;
