import styled from "styled-components";
import { Link } from "gatsby";

export const LinkContainer = styled(Link)``;

export const Text = styled.h1`
  color: #66c0ec;
  font-size: 7rem;
  text-align: center;
  border-bottom: none;
`;

export const Emphasised = styled.em``;
