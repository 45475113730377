import React from "react";

import { LinkContainer, Text, Emphasised } from "./styles";

const Logo: React.FunctionComponent = () => {
  return (
    <LinkContainer to="/">
      <Text>
        Text<Emphasised>ise</Emphasised>
      </Text>
    </LinkContainer>
  );
};

export default Logo;
