// public
import React from "react";

// local
import * as UI from "./styles";

const Nav: React.FunctionComponent = () => {
  return (
    <UI.Container>
      <UI.Links>
        <UI.InternalLink to="/" activeClassName="active">
          Home
        </UI.InternalLink>
        <UI.InternalLink
          to="/textiseOptions.aspx"
          activeClassName="active"
          partiallyActive
        >
          Options
        </UI.InternalLink>
        <UI.InternalLink
          to="/Bookmarklet.aspx"
          activeClassName="active"
          partiallyActive
        >
          Bookmarklet
        </UI.InternalLink>
      </UI.Links>
    </UI.Container>
  );
};

export default Nav;
