import styled, { css } from "styled-components";
import { Link as GatsbyLink } from "gatsby";

export const Container = styled.footer`
  background-color: #eee;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  font-size: 0.85rem;
  display: flex;
  flex-direction: row;
`;

export const Links = styled.div`
  flex: 1;
`;

const linkStyling = css`
  color: #555;
  display: inline-block;
  margin: 0 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

export const InternalLink = styled(GatsbyLink)`
  ${linkStyling}
`;
export const ExternalLink = styled.a`
  ${linkStyling}
`;
export const RightSideText = styled.div``;
