import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
}

interface TitleProps {
  title: string;
  titleTemplate?: string;
}

const SEO: React.FunctionComponent<SEOProps> = (props: SEOProps) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const metaDescription = props.description || site.siteMetadata.title;

  const titleProps: TitleProps = {
    title: props.title || site.siteMetadata.title,
  };

  const solidMeta = [
    {
      name: "description",
      content: metaDescription,
    },
    {
      property: "og:title",
      content: titleProps.title,
    },
    {
      property: "og:description",
      content: metaDescription,
    },
    {
      name: "twitter:card",
      content: "summary",
    },
    {
      name: "twitter:title",
      content: titleProps.title,
    },
    {
      name: "twitter:description",
      content: metaDescription,
    },
  ];

  if (props.title) titleProps.titleTemplate = `%s | ${site.siteMetadata.title}`;

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: props.lang || "en" }}
        {...titleProps}
        meta={solidMeta}
      />
    </>
  );
};

export default SEO;
