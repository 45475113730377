import * as React from "react";
import styled from "styled-components";

import Wrapper from "../Wrapper";
import Footer from "../Footer";

import "./global.css";

const Main = styled.main``;

export type LayoutProps = {
  children: React.ReactNode;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
}: LayoutProps) => {
  return (
    <>
      <Wrapper>
        <Main>{children}</Main>
      </Wrapper>

      <Footer />
    </>
  );
};

export default Layout;
